<template>
    <HomeIndex/>
    <!-- <RouterView></RouterView> -->
</template>

<script>
import HomeIndex from './components/HomeIndex.vue'

export default {
  name: 'App',
  components: {
    HomeIndex
  }
}
</script>

<style>
@import "./style/main.css";
</style>
